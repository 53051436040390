import React, { useEffect, useRef } from 'react';
import './VideoPlayer.css'

function VideoPlayer({ videoSource, title }) {
    const videoContainerRef = useRef(null);
  
    useEffect(() => {
      const videoContainer = videoContainerRef.current;
  
      const resizeVideo = () => {
        if (videoContainer) {
          const width = videoContainerRef.current.offsetWidth;
          const height = (width * 9) / 16; // 16:9 aspect ratio
          videoContainer.style.height = `${height}px`;
        }
      };
  
      window.addEventListener('resize', resizeVideo);
      resizeVideo(); // Initial resize
  
      return () => {
        window.removeEventListener('resize', resizeVideo);
      };
    }, []);
    return (
        <div ref={videoContainerRef} className="game-video">
            <iframe 
                width="100%"
                height="350px"
                className='game-video'
                allow='accelerometer; encrypted-media; gyroscope; picture-in-picture full'
                src={videoSource}
                title={title}
            ></iframe>
        </div>
    )
}

export default VideoPlayer