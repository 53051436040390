import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes} from '@fortawesome/free-solid-svg-icons'
import './Navbar.css';

import companyLogo from '../Assets/tinyhill-logo-yellow.png'
import SocialLinks from './SocialLinks';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleClick = () => setMenuOpen(!menuOpen)
    const closeMobileMenu = () => setMenuOpen(false)

    return (
        <>
        <nav className="navbar">
            <div className='navbar-container'>
                <div className="navbar-container-left"> 
                    <a href="#top" className="navbar-logo">
                        <img
                            alt="CompanyLogo"
                            src={companyLogo}
                            loading="eager"
                            className="company-logo"
                        />
                    </a>
                    <ul id='primary-navigation' className={menuOpen ? "nav-menu active": "nav-menu"}>
                        <li className='nav-item'>
                            <a href='#top' className='nav-links links' onClick={closeMobileMenu}>
                                Home
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href='#games' className='nav-links links' onClick={closeMobileMenu}>
                                Our Games
                            </a>
                        </li>
                    </ul>
                </div>
                <h1 className='navbar-center-text'> TinyHill </h1>
                <div className="navbar-container-right">
                    <SocialLinks hideWhenSmall = {true}/>
                    <div aria-controls='primary-navigation' className='menu-icon' onClick={handleClick}>
                        <FontAwesomeIcon icon={menuOpen ? faTimes: faBars} fixedWidth/>
                    </div>
                </div>
            </div>
        </nav>
        </>
    )
}

export default Navbar
