import './App.css';

import Navbar from './components/Navbar';
import HeroSection from './Pages/HeroSection';
import GameSection from './Pages/GamesSection'
import Footer from './components/Footer';


function App() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <GameSection />
      <Footer />
    </>
  );
}


export default App;
