import React from 'react'
import './SocialLinks.css'
export default function SocialLinks(props) {
    const {hideWhenSmall} = props;
    return (
        <div className={hideWhenSmall ? "social-links-container size-hider" : "social-links-container"}>
            <a
                href="https://twitter.com/TinyHillStudio"
                target="_blank"
                rel="noreferrer noopener"
                className="social-link"
            >
                <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="social-icon"
                >
                    <path
                        d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
                    ></path>
                </svg>
            </a>
            <a
                href="https://www.facebook.com/TinyHillStudio"
                target="_blank"
                rel="noreferrer noopener"
                className="social-link"
            >
                <svg viewBox="0 0 877.7142857142857 1024" className="social-icon">
                    <path
                    d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"
                    ></path>
                </svg>
            </a>
            <a
                href="https://www.instagram.com/tinyhillstudio/"
                target="_blank"
                rel="noreferrer noopener"
                className="social-link"
            >
                <svg viewBox="0 0 877.7142857142857 1024" className="social-icon">
                    <path
                    d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                    ></path>
                </svg>
            </a>
            <a
                href="https://www.reddit.com/r/TinyHill/"
                target="_blank"
                rel="noreferrer noopener"
                className="social-link"
            >
                <svg viewBox="0 0 877.7142857142857 1024" className="social-icon">
                    <path
                    d="M536.571 645.143c4 4 4 10.857 0 14.857-28 28-81.714 30.286-97.714 30.286s-69.714-2.286-97.714-30.286c-4-4-4-10.857 0-14.857 4-4.571 10.857-4.571 14.857 0 17.714 17.714 56 24 82.857 24s65.143-6.286 82.857-24c4-4.571 10.857-4.571 14.857 0zM386.286 556c0 24-20 44-44 44s-44-20-44-44c0-24.571 20-44 44-44s44 19.429 44 44zM579.429 556c0 24-20 44-44 44s-44-20-44-44c0-24.571 20-44 44-44s44 19.429 44 44zM702.286 497.143c0-32-26.286-58.286-58.857-58.286-16 0-30.857 6.857-41.714 17.714-40-27.429-93.714-45.143-152.571-46.857l30.857-138.857 97.714 22.286c0.571 24 20 43.429 44 43.429s44-20 44-44-20-44-44-44c-17.143 0-32 9.714-39.429 24.571l-108-24c-5.714-1.714-10.857 2.286-12 7.429l-34.286 153.143c-58.857 2.286-112 20-151.429 47.429-10.857-11.429-25.714-18.286-42.286-18.286-32.571 0-58.857 26.286-58.857 58.286 0 23.429 13.714 43.429 33.143 53.143-1.714 8.571-2.857 18.286-2.857 27.429 0 93.143 104.571 168.571 233.714 168.571s234.286-75.429 234.286-168.571c0-9.714-1.143-18.857-3.429-28 18.857-9.714 32-29.714 32-52.571zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                    ></path>
                </svg>
            </a>
            <a
                href="https://www.youtube.com/channel/UCBLGNPB9Ag_mHClZ5r-9gjA"
                target="_blank"
                rel="noreferrer noopener"
                className="social-link"
            >
                <svg viewBox="0 0 1024 1024" className="social-icon">
                    <path d="M960 509.2c0-2.2 0-4.7-.1-7.6-.1-8.1-.3-17.2-.5-26.9-.8-27.9-2.2-55.7-4.4-81.9-3-36.1-7.4-66.2-13.4-88.8a139.52 139.52 0 0 0-98.3-98.5c-28.3-7.6-83.7-12.3-161.7-15.2-37.1-1.4-76.8-2.3-116.5-2.8-13.9-.2-26.8-.3-38.4-.4h-29.4c-11.6.1-24.5.2-38.4.4-39.7.5-79.4 1.4-116.5 2.8-78 3-133.5 7.7-161.7 15.2A139.35 139.35 0 0 0 82.4 304C76.3 326.6 72 356.7 69 392.8c-2.2 26.2-3.6 54-4.4 81.9-.3 9.7-.4 18.8-.5 26.9 0 2.9-.1 5.4-.1 7.6v5.6c0 2.2 0 4.7.1 7.6.1 8.1.3 17.2.5 26.9.8 27.9 2.2 55.7 4.4 81.9 3 36.1 7.4 66.2 13.4 88.8 12.8 47.9 50.4 85.7 98.3 98.5 28.2 7.6 83.7 12.3 161.7 15.2 37.1 1.4 76.8 2.3 116.5 2.8 13.9.2 26.8.3 38.4.4h29.4c11.6-.1 24.5-.2 38.4-.4 39.7-.5 79.4-1.4 116.5-2.8 78-3 133.5-7.7 161.7-15.2 47.9-12.8 85.5-50.5 98.3-98.5 6.1-22.6 10.4-52.7 13.4-88.8 2.2-26.2 3.6-54 4.4-81.9.3-9.7.4-18.8.5-26.9 0-2.9.1-5.4.1-7.6v-5.6zm-72 5.2c0 2.1 0 4.4-.1 7.1-.1 7.8-.3 16.4-.5 25.7-.7 26.6-2.1 53.2-4.2 77.9-2.7 32.2-6.5 58.6-11.2 76.3-6.2 23.1-24.4 41.4-47.4 47.5-21 5.6-73.9 10.1-145.8 12.8-36.4 1.4-75.6 2.3-114.7 2.8-13.7.2-26.4.3-37.8.3h-28.6l-37.8-.3c-39.1-.5-78.2-1.4-114.7-2.8-71.9-2.8-124.9-7.2-145.8-12.8-23-6.2-41.2-24.4-47.4-47.5-4.7-17.7-8.5-44.1-11.2-76.3-2.1-24.7-3.4-51.3-4.2-77.9-.3-9.3-.4-18-.5-25.7 0-2.7-.1-5.1-.1-7.1v-4.8c0-2.1 0-4.4.1-7.1.1-7.8.3-16.4.5-25.7.7-26.6 2.1-53.2 4.2-77.9 2.7-32.2 6.5-58.6 11.2-76.3 6.2-23.1 24.4-41.4 47.4-47.5 21-5.6 73.9-10.1 145.8-12.8 36.4-1.4 75.6-2.3 114.7-2.8 13.7-.2 26.4-.3 37.8-.3h28.6l37.8.3c39.1.5 78.2 1.4 114.7 2.8 71.9 2.8 124.9 7.2 145.8 12.8 23 6.2 41.2 24.4 47.4 47.5 4.7 17.7 8.5 44.1 11.2 76.3 2.1 24.7 3.4 51.3 4.2 77.9.3 9.3.4 18 .5 25.7 0 2.7.1 5.1.1 7.1v4.8zM423 646l232-135-232-133z"/>
                </svg>
            </a>
            <a
                href="https://www.twitch.tv/tinyhillstudio"
                target="_blank"
                rel="noreferrer noopener"
                className="social-link"
            >
                <svg viewBox="0 0 16 16" fill="" className="social-icon">
                    <path d="M3.857 0 1 2.857v10.286h3.429V16l2.857-2.857H9.57L14.714 8V0H3.857zm9.714 7.429-2.285 2.285H9l-2 2v-2H4.429V1.143h9.142v6.286z"/>
                    <path d="M11.857 3.143h-1.143V6.57h1.143V3.143zm-3.143 0H7.571V6.57h1.143V3.143z"/>
                </svg>
            </a>
            <a
                href="https://itch.io/profile/tinyhill"
                target="_blank"
                rel="noreferrer noopener"
                className="social-link"
            >
                <svg viewBox="0 0 210 210" className="social-icon">
                    <path d="M38 26.25l-23 27v7.687c0 9.747 8.96 18.313 17.094 18.313 9.767 0 17.905-8.233 17.906-18 0 9.767 7.857 18 17.625 18S85 71.017 85 61.25c0 9.767 8.357 18 18.125 18 9.766 0 17.875-8.233 17.875-18 0 9.767 7.89 18 17.656 18 9.768 0 17.344-8.233 17.344-18 0 9.767 8.39 18 18.156 18 9.768 0 17.844-8.233 17.844-18v-8l-25-27c-43.646-.116-129 0-129 0z" fillRule="evenodd"/>
                    <path d="M50.006 74.75c-.93 1.66-2.224 3.08-3.563 4.412C42.757 82.828 37.672 85.1 32.1 85.1c-1.434 0-5.094-.845-5.094-.845v100h154V83.693c-2.3.904-4.234 1.406-6.844 1.406-5.577 0-10.656-2.27-14.344-5.938-1.336-1.332-2.477-2.843-3.406-4.5-.93 1.66-2.1 3.166-3.437 4.5-3.688 3.663-8.74 5.937-14.313 5.937-5.575 0-10.657-2.27-14.344-5.938-1.336-1.332-2.508-2.844-3.437-4.5-.93 1.66-2.065 3.166-3.405 4.5-3.688 3.663-8.77 5.937-14.344 5.937s-10.656-2.27-14.343-5.938c-1.337-1.33-2.477-2.844-3.406-4.5-.93 1.66-2.095 3.167-3.436 4.5C78.257 82.828 73.2 85.1 67.63 85.1c-5.574 0-10.656-2.27-14.343-5.938-1.336-1.33-2.35-2.756-3.28-4.412zm22.15 18.475c6.717 0 11.495 4.35 13.188 10.906h37.125c1.69-6.556 6.437-10.905 13.155-10.905 10.67 0 22.4 10.956 26.062 24.344l8.75 31.968c3.663 13.387-2.08 24.343-12.75 24.343-9.367 0-19.55-8.44-24.375-19.56H74.468c-4.824 11.12-15.006 19.56-24.374 19.56-10.672 0-16.413-10.955-12.75-24.343l8.75-31.968c3.662-13.39 15.39-24.345 26.062-24.345z"/>
                    <path d="M90.743 121.55L78.205 135h12.352v13.866h24.625V135h13.174l-12.54-13.45-12.536-13.45z"/>
                </svg>
            </a>
        </div>
    )
}
