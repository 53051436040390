import React from 'react'

import './HeroSection.css';


function HeroSection() {
    return (
        <div id='home' className='hero-container'>
            <div className='hero-text'>
                <h1 className="section-heading">A Tiny Studio From Sweden</h1>
                <span className="hero-text section-text">
                    <span>
                    Welcome to TinyHill, a tiny Swedish indie game development company. As a small studio, we value creativity and innovation, striving to create fun experiences that resonate with players worldwide. Welcome to the Tinyhill community, where gaming adventures await!
                    </span>
                </span>
            </div>
        </div>
    )
}

export default HeroSection
