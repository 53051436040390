import React from 'react'
import VideoPlayer from '../components/VideoPlayer';

import './GamesSection.css';



function GamesSection() {
    return (
        <div id='games' className='games-section'>
            <div className='heading-container'>
                <h1 className='section-heading'>Our Games</h1>
            </div>

                <div className='game-container'>
                    <div className='game-card'>
                        <VideoPlayer
                        videoSource={"https://www.youtube-nocookie.com/embed/lj67uZOJQOc"}
                        title={"Luftangrepp - Official Open Beta Release Trailer"} />
                        <div className='achtung-text-container'>
                            <p className='card-title'> Luftangrepp</p>
                            <p className='card-text'>
                            Luftangrepp throws you into the hot seat of an action-packed arcade game where it's just you, your plane, and a whole lot of enemy invaders. They're hungry for your base, but you're not going down without a fight. Just watch your ammo - too many missed shots and it's game over!</p>
                            <iframe className='buy-button-large'
                             title='large-buy'
                             src="https://itch.io/embed/1111963?linkback=true&amp;border_width=0&amp;bg_color=00437a&amp;fg_color=e7f3f8&amp;link_color=ffffff&amp;border_color=505868"
                              width="550"
                               height="150">
                                <a href="https://tinyhill.itch.io/luftangrepp">Luftangrepp by TinyHill</a></iframe>   
                            <iframe className='buy-button-small'
                            title='small-buy'
                              src="https://itch.io/embed/1111963?linkback=true&amp;border_width=0&amp;bg_color=00437a&amp;fg_color=e7f3f8&amp;link_color=ffffff&amp;border_color=505868" 
                              width="206"
                               height="165">
                                <a href="https://tinyhill.itch.io/luftangrepp">Luftangrepp by TinyHill</a></iframe>
                            </div>
                    </div>
                </div>
            <p className='end-text'> Find and buy our games at Itch.io</p>
                <a
                    href='https://tinyhill.itch.io'
                    target="_blank"
                    rel="noreferrer noopener"
                    className='more-games-button button'
                >
                    More Games
                </a>
        </div> 
    )
}

export default GamesSection
