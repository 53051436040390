import React from 'react'
import './Footer.css'
import SocialLinks from './SocialLinks'

export default function Footer() {
    return (
        <div className='footer'>
            <div className='footer-container-left'>
                <SocialLinks />
            </div>
            <div className='footer-container-right'>
            </div>
        </div>
        
    )
}
